// Promise polyfill
import 'core-js/features/promise';
import React from "react";
import { createRoot } from "react-dom/client";
import '@amzn/awsui-global-styles/polaris.css';
import '@xterm/xterm/css/xterm.css';
import { hideLoading, showLoading } from "./components/Loading";
import { getMidwayJwtToken } from "./auth/MidwayJwtToken";
import axios from "axios";
import { initializeAppSettings } from "./config/AppSettings";
import DreamscapeApiFactory from "./dreamscape-api/DreamscapeApiFactory";
import { User, Regions } from "@amzn/coral_com-amazonaws-console-dreamscape-model";
import { BrowserRouter, Route, Switch, useParams } from "react-router-dom";
import Create from "./components/Create";
import Connect from "./components/Connect";
import Home from "./components/Home";
import { RouteComponentProps } from "react-router";
import ConnectProps from "./components/ConnectProps";
import Sessions from "./components/Sessions";

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  config.headers.Authorization = `Bearer ${jwtToken}`
  return config;
});

const renderApp = () => {
  const root = createRoot(document.getElementById('root')!);
  root.render(
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => {
          return (<Home/>)
        }}/>
        <Route path="/sessions" render={() => {
          return (<Sessions/>)
        }}/>
        <Route path="/create">
          <Create/>
        </Route>
        <Route path="/connect/:sessionId" render={(props: RouteComponentProps<ConnectProps>) => {
          return (<Connect {...props}/>)
        }}/>
      </Switch>
    </BrowserRouter>
  )
  ;
}

(async () => {
  // Make sure Midway is present before rendering
  await getMidwayJwtToken();

  // Initialize application settings
  const appSettings = (await axios('/settings.json')).data;
  initializeAppSettings(appSettings);

  const dreamscapeApi = DreamscapeApiFactory();
  showLoading();
  dreamscapeApi.whoAmI()
  .then(resp => {
    const user = resp.data.user!;
    const stage = resp.data.stage || "prod";
    localStorage.setItem("user", JSON.stringify(User.toJson(user)));
    localStorage.setItem("stage", stage);
    localStorage.setItem("emailDomain", resp.data.emailDomain || "amazon.com");
    dreamscapeApi.listRegions().then(resp => {
      const regions = resp.data.regions!;
      localStorage.setItem("regions", JSON.stringify(Regions.toJson(regions)));
      renderApp();
      hideLoading();
    }).catch(reason => {
      renderApp();
      hideLoading();
    })
  }).catch(reason => {
    renderApp();
    hideLoading();
  });
})();
