import { SideNavigation } from "@amzn/awsui-components-react";
import React from "react";

export default function SideNav() {
  return (
    <SideNavigation
      items={[
        {
          type: "section",
          text: "Console Bastion",
          items: [
            { type: "link", text: "Sessions", href: "/sessions" }
          ]
        }
      ]}
    />
  );
}
